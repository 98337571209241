import React from "react";
import { IntlProvider, FormattedDate } from 'react-intl';

export function convertDateForDisplay(date) {
  if(date){
    return (
      <FormattedDate value={date} day="2-digit" month="2-digit" year="numeric"/>
    )
  }
  return;
}