// @ flow
import React, { Component } from 'react'
import { convertDateForDisplay } from '../helper/utils'
import axios from 'axios'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
// import validate from '../validation/validate';
// import * as config from "../../config/config";
// import * as config from "../../../public/config/config"

const BASE_PATH = process.env.REACT_APP_BASE_PATH
// const BASE_PATH = "http://10.200.0.6/licensebot-api/";
const API_PATH = BASE_PATH

class Form extends Component {
  constructor () {
    super()
    this.state = {
      hardware_id: undefined,
      customer_id: undefined,
      expire_date: undefined,
      license_no: undefined,
      api_error_code: undefined,
      api_error_message: undefined,
      license_no_copied: false,
      data_copied: false,
      form_error_message1: undefined,
      form_error_message2: undefined,
      check_input_1: false,
      check_input_2: false
    }
    // this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.checkValue = this.checkValue.bind(this)
  }

  // handleChange (event) {
  //   if (this.state.license_no || this.state.api_error_code || this.state.api_error_message) {
  //     this.setState({
  //       expire_date: undefined,
  //       license_no: undefined,
  //       api_error_code: undefined,
  //       api_error_message: undefined
  //     })
  //   }
  //
  //   let inputValue = event.target.value
  //
  //   if (event.target.maxLength >= 0) {
  //     inputValue = inputValue.slice(0, event.target.maxLength)
  //   }
  //
  //   this.setState({ [event.target.id]: inputValue })
  //   event.target.value = inputValue
  //
  // }

  checkValue (event) {
    let inputValue = event.target.value
    if (inputValue.length !== event.target.maxLength) {
      document.getElementById('submit').disabled = true
      if (event.target.maxLength == 10) {
        let error_message = 'Die Hardware-Nr. muss 10 Stellen lang sein'
        this.setState({
          form_error_message2: error_message,
          check_input_2: false
        })
      } else if (event.target.maxLength == 5) {
        let error_message = 'Die Kunden-Nr. muss 5 Stellen lang sein'
        this.setState({
          form_error_message1: error_message,
          check_input_1: false
        })
      }
    } else {
      if (event.target.maxLength == 10) {
        let error_message = ''
        this.setState({
          form_error_message2: error_message,
          check_input_2: true
        })
      } else if (event.target.maxLength == 5) {
        let error_message = ''
        this.setState({
          form_error_message1: error_message,
          check_input_1: true
        })
      }
    }

    if (this.state.license_no || this.state.api_error_code || this.state.api_error_message) {
      this.setState({
        expire_date: undefined,
        license_no: undefined,
        api_error_code: undefined,
        api_error_message: undefined
      })
    }

    if (event.target.maxLength >= 0) {
      inputValue = inputValue.slice(0, event.target.maxLength)
    }

    this.setState({ [event.target.id]: inputValue })
    event.target.value = inputValue
  }

  handleSubmit (event) {
    event.preventDefault()
    const url = API_PATH
    const meta = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    const querystring = require('querystring')

    let data = {
      data: JSON.stringify(this.state),
      which_data: 'license_simple'
    }

    data = querystring.stringify(data)

    axios.post(url, data, meta)
      .then(result => {
        let expire_date
        let license_no
        let api_error_code
        let api_error_message

        if (result && result.data) {
          const response = result.data
          if (response.result) {
            if (response.data) {
              expire_date = response.data.expire_date
              license_no = response.data.license_no

            }
          } else {
            api_error_code = response.error
            api_error_message = response.error_text
          }
        }
        this.setState({
          expire_date: expire_date,
          license_no: license_no,
          api_error_code: api_error_code,
          api_error_message: api_error_message
        })
      })
      .catch(error => {
        this.setState({
          api_error_code: '',
          api_error_message: error.message
        })
      })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    document.getElementById('submit').disabled = !(this.state.check_input_1 && this.state.check_input_2);
  }

  render () {
    const formattedExpireDate = convertDateForDisplay(this.state.expire_date)

    return (
      <div className="full-width">
        <form className="full-width" onSubmit={this.handleSubmit}>
          <div className="form-group align-left">
            <label htmlFor="customer_id">Kunden-Nr.</label>
            <input
              type="number"
              className="form-control"
              id="customer_id"
              maxLength="5"
              value={this.state.value}
              onChange={this.checkValue}
              // onBlur={this.checkValue}
              required
            />
          </div>
          <div className={this.state.form_error_message1 ? '' : 'hidden'}>
            <p className="align-left font-danger">Fehler: {this.state.form_error_message1}</p>
          </div>
          <div className="form-group align-left">
            <label htmlFor="hardware_id">Hardware-Nr.</label>
            <input
              type="number"
              className="form-control"
              id="hardware_id"
              value={this.state.value}
              maxLength="10"
              onChange={this.checkValue}
              // onBlur={this.checkValue}
              required
            />
          </div>
          <div className={this.state.form_error_message2 ? '' : 'hidden'}>
            <p className="align-left font-danger">Fehler: {this.state.form_error_message2}</p>
          </div>
          <br/>
          <button type="submit" className="btn btn-primary btn-lg" id="submit" disabled>
            SENDEN
          </button>

        </form>
        <br/>
        <br/>
        <br/>
        <div className={this.state.license_no ? '' : 'hidden'}>
          <div className="bordered border-success box-padding">
            <table id="license-info-table" className="align-left">
              <tbody>
              <tr>
                <td>Lizenz-Nr.:</td>
                <td>{this.state.license_no}</td>
              </tr>
              </tbody>
            </table>
            <hr/>
            <div id="copy-button-container" className="col-sm-12">
              <CopyToClipboard text={this.state.license_no}
                               onCopy={() => this.setState({ license_no_copied: true })}>
                <button
                  className={'btn copy-button align-center' + (this.state.license_no_copied ? ' btn-success' : ' btn-primary')}>
                  <FontAwesomeIcon icon={faCopy}/>&nbsp;Lizenznummer kopieren
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
        <div className={this.state.form_error_message ? '' : 'hidden'}>
          <p className="align-left font-danger">Fehler: {this.state.api_error_message}</p>
        </div>
        <div className={this.state.api_error_code || this.state.api_error_message ? '' : 'hidden'}>
          <p className="align-left font-danger">Fehler {this.state.api_error_code}: {this.state.api_error_message}</p>
        </div>
      </div>
    )
  }
}

export default Form
