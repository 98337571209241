import React, { Component } from 'react'
import Form from './Form.jsx'
import '../../css/own-styles.css'

// import '../../css/site.css'

class Iframe extends Component {
  componentWillMount(){
    document.querySelector('body').style.backgroundImage = 'none'
  }

  render () {
    return (
      <div id="popover-container" className="row box-padding center align-center bordered border-neutral col-md-6 mt-5">
        <div className="center align-center full-width">
          <Form/>
        </div>
      </div>
    )
  }
}

export default Iframe
