import React from 'react'
import Form from './Form.jsx'
import '../../css/own-styles.css'

const App = () => (
  <div className="page-container">
    <div className="logo-container">
      <img src="/img/posbill_logo.png" alt="Logo"/>
    </div>
    <div id="popover-container" className="license-container row box-padding center align-center bordered border-neutral col-md-6 mt-5">
      <div className="center align-center full-width">
        <h1 className="font-primary">Hallo Du</h1>
        <br/>
        <br/>
        <h2>Hier geht´s zu Deiner neuen Lizenznummer</h2>
        <p className="font-dark">Nachdem Du die beiden Felder ausgefüllt hast, erscheint Deine aktuell gültige Lizenznumme</p>
        <br/>
        <Form/>
      </div>
    </div>
    <div className="text-container">
      <p>Wunschtermin online buchen unter <a target="_blank" href="https://termine.posbill.com">termine.posbill.com</a></p>
    </div>
  </div>
)
export default App
