import React from 'react'
import ReactDOM from 'react-dom'
import { Route, BrowserRouter as Router } from 'react-router-dom'
import Index from './index'
import IframeContainer from './iframe'

const routing = (
  <Router>
    <div>
      <Route path="/iframe" component={IframeContainer} />
      <Route exact path="/" component={Index} />
    </div>
  </Router>
)

ReactDOM.render(routing, document.getElementById('root'))
