import React from "react";
import { render } from "react-dom";
import { IntlProvider, addLocaleData } from "react-intl";
import { Provider } from "react-redux";
import App from "./components/App.jsx";
// import { LANG } from "./../config/config"
import locale_en from 'react-intl/locale-data/en';
import locale_de from 'react-intl/locale-data/de';

addLocaleData([...locale_en, ...locale_de]);

const Index = () => {
  return (
    render(
      <IntlProvider locale={process.env.REACT_APP_LANG}>
        <App/>
      </IntlProvider>,
      document.getElementById('root')
    )
  )
}

export default Index
